// We need to initialize common page elements,
// as this entrypoint isn't initialized by the main application.js
import "entry/common"

import $ from "jquery"
import { isSessionActive } from "lib/currentUser/session"
import { initializeCommonPageElements } from "pages/common"

const showMoreExperts = (event) => {
  event.preventDefault()
  $(".expert-authors").addClass("show-all")
}

const clickHandheldTab = (event) => {
  event.preventDefault()
  const section = $(event.target).attr("href")
  $("#tabbed-navigation a.active").removeClass("active")
  $(event.target).addClass("active")
  switch (section) {
    default:
      $(".topic-page").removeClass("sm-display-contributors sm-display-links")
      $(".topic-page").addClass("sm-display-articles")
      break
    case "#contributors":
      $(".topic-page").removeClass("sm-display-articles sm-display-links")
      $(".topic-page").addClass("sm-display-contributors")
      break
    case "#links":
      $(".topic-page").removeClass("sm-display-articles sm-display-contributors")
      $(".topic-page").addClass("sm-display-links")
      break
  }
}

const setTopicFollowedState = (following) => {
  if (following) {
    $(".actions .follow-link").addClass("followed")
  } else {
    $(".actions .follow-link").addClass("not-followed")
  }
}

const fetchTopicFollowedState = () => {
  $.ajax({
    type: "GET",
    url: `${document.location.pathname}/following`,
    success: ({ following }) => setTopicFollowedState(following),
    failure: () => setTopicFollowedState(false),
  })
}

const initializeHandheldTabEvents = () => {
  $("#tabbed-navigation a").on("click", clickHandheldTab)
  $(".expert-authors .more a").on("click", showMoreExperts)
}

async function initialize() {
  initializeCommonPageElements()
  initializeHandheldTabEvents()

  if (isSessionActive()) {
    fetchTopicFollowedState()
  } else {
    setTopicFollowedState(false)
  }
}

TC.EntrypointInitializer = initialize
